<template>
    <div class="main">
        <div style="width: 100vw;">
            <!-- 金融 -->
            <img style="width: 100%;" src="@/assets/jinrong.png" alt="">

        </div>
        <!-- 搜索 -->
        <div class="search_contariner" v-if="detail != null">
            <div class="w12" style="padding: 60px 50px;">
                <div class="left_img">
                    <img v-if="detail.thumb != '' && detail.thumb != '1'" :src="detail.thumb" alt="">
                    <!-- <img src="../assets/img/main/logo-hover.png" alt=""> -->
                </div>
                <div class="right_content">
                    <div>
                        <div class="content_title">
                            {{ detail.title }}
                        </div>
                        <div class="content_tags">
                            <div class="everyTag" v-for="(item, index) in detail.tagArr" :key="index">
                                {{ item }}
                            </div>
                        </div>
                        <div class="content_words">
                            {{ detail.introduce }}
                        </div>
                    </div>
                    <div class="content_footer">
                        <div class="footer_time">发布于{{ detail.releaseTime }}</div>
                        <div class="footer_look">
                            <div class="look_title" @click="dialogVisible = true">
                                查看原网页
                            </div>

                            <div class="look_ico">
                                <img src="@/assets/rightGreen.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <el-empty :image-size="200"></el-empty>
        </div>

        <div>
            <el-dialog top="160px" title="" :show-close="false" :visible.sync="dialogVisible" width="700px"
                :before-close="handleClose">
                <div style="padding: 0 80px;">
                    <div class="tishi">
                        <div class="tishi_ico">
                            <img src="@/assets/warn_ico.png" alt="">
                        </div>
                        <div class="tishi_title">
                            风险提示
                        </div>
                    </div>
                    <div class="tishi_content">
                        您所访问的页面将跳转到第三方网站，可能会有安全风险，确定要继续吗
                    </div>
                    <div class="btns">
                        <div class="btn quxiao" @click="dialogVisible = false">
                            取消
                        </div>
                        <div class="btn yes" @click="goToUrl">
                            确认跳转
                        </div>
                    </div>

                </div>

            </el-dialog>

        </div>

    </div>
</template>
<script>
let id = 0;
import { banner } from "@/api/banner";
import house from "@/api/housedetail";
import mainpage from "@/api/mainpage";
import Card from "../components/Card/index.vue";
// import { EffectCube, Pagination } from "swiper";

export default {
    components: {
        Card,
    },
    data() {
        let that = this;
        return {
            dialogVisible: false,
            prev: [{
                id: 1,
                value: '1',
                label: '河南省'
            }, {
                value: '选项2',
                label: '河北省'
            }],
            city: [{
                id: 1,
                value: '1',
                label: '郑州市'
            }, {
                value: '选项2',
                label: '周口市'
            }],
            value: '',
            handleSizeChange(val) {
                console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
                console.log(`当前页: ${val}`);
            },
            //  搜索器
            currentPage1: 5,
            currentPage2: 5,
            currentPage3: 5,
            currentPage4: 4,
            // modules: [EffectCube, Pagination],
            preActiveNum: 0,
            imgList: [
                require("../assets/logo/logo5.jpg"),
                require("../assets/logo/logo3.jpg"),
                // require("../assets/logo/logo4.jpg"),
                require("../assets/logo/logo1.jpg"),
                require("../assets/logo/logo2.jpg"),
                require("../assets/logo/logo6.jpg"),
                require("../assets/logo/logo7.jpg"),
                require("../assets/logo/logo8.jpg"),
                require("../assets/logo/logo9.jpg"),
            ],
            activeName: 'first',
            index: 0,
            selectedOptions: [],
            navInputProps: {
                lazy: true,
                value: "id",
                label: "name",
                lazyLoad: (node, resolve) => {
                    const { level } = node;
                    house
                        .mapList({
                            showAll: 0,
                            pid: node.level === 0 ? null : node.data.id,
                        })
                        .then((res) => {
                            res.data.forEach((it) => {
                                it.leaf = level >= 2;
                            });
                            resolve(res.data);
                        });

                    // setTimeout(() => {
                    //   const nodes = Array.from({ length: level + 1 }).map((item) => ({
                    //     value: ++id,
                    //     label: `选项${id}`,
                    //     leaf: level >= 2,
                    //   }));
                    //   // 通过调用resolve将子节点数据返回，通知组件数据加载完成
                    //   resolve(nodes);
                    // }, 1000);
                },
            },
            swiperOption: {
                effect: "cube",
                cubeEffect: {
                    slideShadows: false,
                    shadow: false,
                },
                grabCursor: true,
                observer: true, //修改swiper自己或子元素时，自动初始化swiper
                observeParents: true,
                // mousewheel: true,
                // loop: true,
                speed: 2000,
                autoplay: {
                    delay: 6 * 1000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false,
                },
                // 显示分页
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true, //允许分页点击跳转
                },
                // 设置点击箭头
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                // coverflowEffect: {
                //   rotate: 0, // 旋转的角度
                //   stretch: 100, // 拉伸   图片间左右的间距和密集度
                //   depth: 150, // 深度   切换图片间上下的间距和密集度
                //   modifier: 3, // 修正值 该值越大前面的效果越明显
                //   slideShadows: false, // 页面阴影效果
                // },
                on: {
                    init: function (swiper) {
                        that.isOver = true;
                    },
                    transitionStart: function () {
                        that.isOver = false;
                    },
                    transitionEnd: function () {
                        that.isOver = true;
                    },
                    // slideChangeTransitionStart() {
                    //   alert("111");
                    //   // setTimeout(() => {
                    //   that.isOver = false;
                    //   // }, 1000);
                    // },
                    // init() {
                    //   that.isOver = true;
                    //   // setTimeout(() => {
                    //   //   that.isOver = false;
                    //   // }, 3000);
                    // },

                    // slideChangeTransitionEnd() {
                    //   // alert("222");
                    //   // setTimeout(() => {
                    //   that.isOver = true;
                    //   // }, 2000);
                    // },
                },
            },
            isOver: false,
            swiperOptionVR: {
                slidesPerView: 3,
                slidesPerGroup: 6,
                // loop: true,
                // loopFillGroupWithBlank: true,
                spaceBetween: 60,
                slidesPerColumnFill: "row",
                slidesPerColumn: 2,

                paginationClickable: true,

                // 显示分页
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true, //允许分页点击跳转
                },
            },
            swiperData: [],
            form: {
                province: "",
                provinceId: "",
                provinceOp: [],
                city: "",
                cityId: "",
                countyId: "",
                cityOp: [],
            },

            searchData: "",
            showActive: false,
            newData: [],
            digital: [],
            activeName: "0",
            one: true,
            two: true,
            three: true,
            four: true,
            wareHouseListVr: {},
            wareHouseList: [],
            type: '',
            lastId: '',
            detailParam: {
                type: 4,
                viewType: 2,
                id: ''
            },

            detail: {},
            selectAll: '',
            education: "",
            checkedList: []
        };
    },
    watch: {
        activeName(cur, pre) {
            this.preActiveNum = pre;
        },
    },
    mounted() {

        this.get();
        // house.shareInfo().then((res) => {
        //   // console.log(0,res);
        // });
        // house
        //   .warehouseDetailById("57606a41cd934e9da378d9724dd50c66")
        //   .then((res) => {
        //     // console.log(111,res);
        //   });
        // house
        //   .warehouseRecommendList("57606a41cd934e9da378d9724dd50c66")
        //   .then((res) => {
        //     // console.log(222,res);
        // });
        // house.commonCategoryList().then((res) => {
        //   console.log(333, res);
        // });
        house.mapList({ showAll: 1, pid: null }).then((res) => {
            this.form.provinceOp = res.data;
            this.cascaderArry = res.data;
            // console.log(444,res);
        });
        // house
        //   .warehouseBannerList("57606a41cd934e9da378d9724dd50c66")
        //   .then((res) => {
        //     // console.log(555,res);
        //   });
        // house
        //   .warehousePageInfo({
        //     pageNum: 1,
        //     pageSize: 10,
        //     warehouseName: "",
        //     province: "",
        //     city: "",
        //     county: "",
        //     categoryIds: [],
        //   })
        //   .then((res) => {
        //     // console.log(666,res);
        //   });
        // house
        //   .mapAreaAndWarehouseInfos({
        //     warehouseName: "",
        //     province: "",
        //     city: "",
        //     county: "",
        //     categoryIds: [],
        //     areaIds: ["120000"],
        //   })
        //   .then((res) => {
        //     // console.log(777,res);
        //   });
        this.wareHouseListVr = {};
        mainpage.homeRecommendlist().then((res) => {
            // console.log(888, res);
            // this.wareHouseList = res.data

            // console.log("res.data", res);

            const data = res.data.reverse();
            this.wareHouseList = data
            // this.wareHouseListVr = data.filter((item) => item.code == "vr")[0];
            // this.wareHouseList = data.filter((item) => item.code !== "vr");
            // console.log(this.wareHouseList);

            // res.data.forEach((it) => {
            //   // console.log(it);
            //   if (it.code == "vr") {
            //     // this.wareHouseListVr = it;
            //   } else {
            //     this.wareHouseList.push(it);
            //   }
            // });
        });

    },
    created() {
        this.type = this.$route.query.type
        console.log(this.type);
        if (this.type == "rec") {
            this.detailParam.type = 3
        } else if (this.type == "ask") {
            this.detailParam.type = 2
        } else {
            this.detailParam.type = 4

        }
        if (this.$route.query.id) this.detailParam.id = this.$route.query.id

        this.getSelect()
    },
    methods: {
        goToUrl() {
            this.dialogVisible = false
            if (this.detail.url.indexOf('http') == -1) {
                window.open('http://' + this.detail.url);
            } else {
                window.open(this.detail.url);
            }


        },
        // 获取初始化选项列表
        async getSelect() {
            let data = await this.$api.base.getSelect()
            // console.log(data);
            this.selectAll = data.info

            this.rentDetail()

        },
        async rentDetail() {
            let data = await this.$api.base.rentDetail(this.detailParam)
            console.log(data);
            this.detail = data.info
            if (this.type == "rec") {
                this.detail.releaseTime = this.$util.formatTime(this.detail.releaseTime, "YY-M-D")
                let arr1 = data.info.welfare.split(",")
                let arr2 = [];
                console.log(this.selectAll);
                for (var i = 0; i < this.selectAll.recruit_welfare.length; i++) {
                    for (var j = 0; j < arr1.length; j++) {
                        if (this.selectAll.recruit_welfare[i].tagId == arr1[j]) {
                            arr2[arr2.length] = this.selectAll.recruit_welfare[i]
                        }
                    }
                }
                for (var i = 0; i < this.selectAll.warehouse_education.length; i++) {
                    if (this.selectAll.warehouse_education[i].tagId == this.detail.education) {
                        this.education = this.selectAll.warehouse_education[i].itemName
                    }
                }
                this.checkedList = arr2
            } else if (this.type == "ask") {
                this.detail.releaseTime = this.$util.formatTime(this.detail.releaseTime, "YY-M-D")
                this.detail.rentBeginTime = this.$util.formatTime(parseInt(this.detail.rentBeginTime), "YY-M-D")
            } else {
                this.detail.banner = this.detail.banner.split(",")
                this.detail.tags = this.detail.tags.split("/")
                this.detail.releaseTime = this.$util.formatTime(this.detail.releaseTime * 1, "YY-M-D")
            }


        },
        handleClose(done) {
            this.$confirm('确认关闭？')
                .then(_ => {
                    done();
                })
                .catch(_ => { });
        },
        handleClick(tab, event) {
            console.log(tab, event);
        },
        get() {
            banner().then((res) => {
                this.swiperData = res.data;
                // console.log(res);
            });
        },

        openNewTabs(url) {
            window.open(url);
        },

        goToMap(val) {
            // console.log("cityw", this.selectedOptions);

            if (val == "more") {
                return this.$router.push({
                    path: "/map",
                });
            }

            this.$router.push({
                path: "/map",
                query: {
                    name: this.searchData,
                    provinceId: this.selectedOptions[0],
                    cityId: this.selectedOptions[1],
                    countyId: this.selectedOptions[2],
                },
            });
        },
        showHouses(it) {
            this.showActive = it === 1 ? true : false;
            if (it) {
                // console.log("housedetail", house);
            } else {
            }
        },
        handleClick(tab, event) {
            // console.log(tab, event);
        },
        mouseIn1(t) {
            this.one = false;
        },
        mouseIn2(t) {
            this.two = false;
        },
        mouseIn3(t) {
            this.three = false;
        },
        mouseIn4(t) {
            this.four = false;
        },
        mouseLeave1(t) {
            this.one = true;
        },
        mouseLeave2(t) {
            this.two = true;
        },
        mouseLeave3(t) {
            this.three = true;
        },
        mouseLeave4(t) {
            this.four = true;
        },
    },
};
</script>
<style scoped lang="less">
/deep/ .el-dialog {

    border-radius: 10px !important;
}

/deep/ .v-modal {
    z-index: 1000 !important;
}

.recruitment {
    .list_detail {
        width: 100%;
        padding: 50px 0 50px 0;

        .detail_title {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .title_content {
                width: 410px;
                height: 25px;
                font-size: 18px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 600;
                color: #333333;
                line-height: 21px;
            }

            .title_time {
                width: 125px;
                height: 22px;
                font-size: 16px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #CCCCCC;
                line-height: 19px;
            }
        }

        .salary {
            margin: 10px 0;

            .salary1 {
                font-size: 20px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #00B079;
                line-height: 23px;
            }

            .salary2 {
                font-size: 20px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                line-height: 23px;
                color: #F23838;
            }
        }

        .detail_tags {
            display: flex;
            flex-wrap: wrap;

            .tag {
                padding: 4px 20px;
                background: #00B079;
                border-radius: 4px 4px 4px 4px;
                font-size: 14px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                margin-right: 30px;
                margin-top: 10px;
            }
        }

        .detail_company {
            display: flex;
            margin: 20px 0;

            .company_name,
            .company_address {
                font-size: 14px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
                line-height: 16px;
            }

            .company_name {
                margin-right: 178px;
            }

        }

        .detail_other {
            padding: 30px 82px;
            display: flex;
            justify-content: space-between;

            .every_other {
                .other_title {
                    font-size: 18px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #999999;
                    line-height: 21px;
                    text-align: center;
                }

                .other_content {
                    font-size: 20px;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    line-height: 23px;
                    text-align: center;
                    margin-top: 13px;
                }
            }
        }

        .detail_require {
            .require_title {
                font-size: 18px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                line-height: 21px;
            }

            .require_content {
                font-size: 16px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
                line-height: 36px;
            }
        }
    }
}

.askforrent {
    padding: 50px 0;


    .list_detail1 {
        width: 100%;

        .detail_title1 {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .title_content1 {
                width: 410px;
                height: 25px;
                font-size: 18px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 600;
                color: #333333;
                line-height: 21px;

                span {
                    color: #F23838;
                }
            }

            .title_time1 {
                width: 125px;
                height: 22px;
                font-size: 16px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #CCCCCC;
                line-height: 19px;
            }
        }

        .detail_rent1 {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 10px 0 0px 0;

            .every_rent1 {
                font-size: 16px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
                line-height: 19px;
            }
        }

        .detail_other1 {
            padding: 30px;

            .every_other1 {
                display: flex;
                align-items: center;
                margin-top: 60px;
                justify-content: space-between;

                .other_title1 {
                    font-size: 18px;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 600;
                    color: #333333;
                    line-height: 21px;
                }

                .other_content1 {
                    width: calc(100% - 120px);

                    .every_content1 {

                        font-size: 16px;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #999999;
                        line-height: 32px;

                        div {
                            font-size: 16px;
                            font-family: PingFang SC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #999999;
                            line-height: 32px;

                            span {
                                font-size: 16px;
                                font-family: PingFang SC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #999999;
                                line-height: 32px;
                            }
                        }

                    }
                }
            }


        }
    }
}

.tishi {
    display: flex;
    border-bottom: 1px solid #999999;
    align-items: center;
    padding-bottom: 30px;

    .tishi_ico {
        width: 36px;
        height: 36px;
        margin-right: 10px;

        img {
            width: 36px;
            height: 36px;

        }
    }

    .tishi_title {

        font-size: 24px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 28px;
    }
}

.tishi_content {

    font-size: 20px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #999999;
    line-height: 23px;
    padding-top: 30px;
}

.btns {
    display: flex;
    justify-content: center;
    margin-top: 50px;

    .btn {
        padding: 4px 30px;
        border-radius: 4px 4px 4px 4px;
        font-size: 20px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;

    }

    .quxiao {
        color: #333333;
        background: #FFFFFF;
        margin-right: 50px;
        cursor: pointer;
    }

    .yes {
        color: #FFFFFF;
        background: #00B079;
        cursor: pointer;

    }
}

.w12 {
    display: flex;

    .left_img {
        width: 240px;
        height: 240px;
        border-radius: 10px 10px 10px 10px;
        overflow: hidden;
        margin-right: 20px;
        // background-color: #F23838;

        img {
            // width: 240px;
            // height: 240px;
            width: 100%;
            height: 100%;
            // margin-top: 80px;
            // height: 100%;
        }
    }

    .right_content {
        width: calc(100% - 250px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .content_title {
            font-size: 18px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 600;
            color: #333333;
            line-height: 21px;
        }

        .content_tags {
            display: flex;

            margin: 10px 0;

            .everyTag {
                font-size: 14px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 16px;
                padding: 4px 20px;
                background: #00B079;
                border-radius: 4px 4px 4px 4px;
                margin-right: 30px;
                color: #FFFFFF;
            }
        }

        .content_words {

            font-size: 16px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 36px;

        }

        .content_footer {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .footer_time {
                font-size: 18px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #CCCCCC;
                line-height: 21px;
            }

            .footer_look {
                display: flex;
                align-items: center;
                cursor: pointer;

                .look_title {
                    font-size: 18px;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #00B079;
                    line-height: 21px;
                }

                .look_ico {
                    width: 24px;
                    height: 24px;

                    img {
                        width: 24px;
                        height: 24px;

                    }
                }
            }
        }
    }
}




.gongsi,
.shijian {
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #CCCCCC;
}

.list_ico img {
    width: 24px;
    height: 24px;
}

img {
    position: relative;
    z-index: 0;
}

.el-o {
    font-weight: 500;
}

.firstActive {
    animation: firstActive 0.5s;
    animation-iteration-count: 1;
    -webkit-animation-iteration-count: 1;
    /*Safari and Chrome*/
}

@keyframes firstActive {
    0% {
        transform: translateX(-500px);
    }

    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}

.secondActive {
    animation: secondActive 0.5s;
    animation-iteration-count: 1;
    -webkit-animation-iteration-count: 1;
    /*Safari and Chrome*/
}

@keyframes secondActive {
    0% {
        transform: translateX(500px);
    }

    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}

.tAsBox {
    animation: fadenum 2s;
    display: block !important;
    animation-iteration-count: 1;
    -webkit-animation-iteration-count: 1;
    /*Safari and Chrome*/
}

@keyframes fadenum {
    0% {
        opacity: 0;
        /* transform: translateY(70px); */
        top: 300px;
    }

    100% {
        opacity: 1;
        top: 250px;
    }
}

.w11 {
    padding: 50px 0 0px 0;
}

.slide-swiper {
    /* width: 357px !important; */
    height: 257px;
    margin-top: 60px;
    /* margin-left: 64px; */
}

.slide-swiper:first-child {
    margin-left: 0;
}

.slide-swiper .card {
    width: 357px !important;
    height: 257px !important;
}

/deep/.slide-swiper .card .card-box {
    width: 357px !important;
    height: 257px !important;
}

.vr-swiper .swiper-container {
    padding-bottom: 76px;
}

.flexws-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flexws-box .warehouse-card {
    min-height: 476px;
}
</style>
